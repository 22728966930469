import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HgStrPtsIcon from "../../assets/images/highStoryPoint.svg";
import LowStrPtsIcon from "../../assets/images/lowStoryPoint.svg";
import MdStrPtsIcon from "../../assets/images/mediumStoryPoint.svg";
import { editTask } from "../../Redux/Slice/Task";
import { isPublicRoute } from "../../Utils/helpers";
function TaskStoryPoint(props) {
  const {
    taskDetail,
    islist,
    taskListId,
    sprintId,
    isSubtaskview,
    refreshPage,
    projectId,
    setProgress,
    isUserProfile,
    isKanban,
  } = props;

  const dispatch = useDispatch();
  const [task, setTask] = useState();
  const [storyPoint, setStoryPoint] = useState("");
  const [prevStoryPoint, setPrevStoryPoint] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const isPublicPage = isPublicRoute();

  useEffect(() => {
    setStoryPoint(taskDetail.task_story_point);
    setTask(taskDetail);
    setPrevStoryPoint(taskDetail.task_story_point);
  }, [taskDetail]);

  const handleEditClick = (event) => {
    if (isPublicPage || isUserProfile || taskDetail?.userPermission?.can_i_edit === false) {
      toast.info("You don't have access to update task", { position: "bottom-right" });
      return;
    }
    setAnchorEl(event.currentTarget);  // Open popover
  };

  const handleClose = () => {
    setAnchorEl(null);  // Close popover
  };

  const handleUpdateTask = (value) => {
    if (value === "" || task.task_story_point === value) {
      return;
    } else if (value >= 0 && value <= 10) {
      setProgress(30);
      const taskId = taskDetail.task_id;
      const payload = new FormData();
      payload.append(`story_point`, value);
      dispatch(editTask({ taskId, payload })).then((resp) => {
        setProgress(60);
        if (!isSubtaskview) {
          refreshPage(
            taskId,
            projectId,
            null,
            null,
            sprintId,
            taskListId,
            resp?.payload?.task,
            "added change inline"
          );
        } else {
          refreshPage(task?.status_id, resp?.payload?.task);
        }
        setProgress(100);
        toast.success("Task points updated successfully!");
        setTask({ task_story_point: value });
        setStoryPoint(value);
        setPrevStoryPoint(value);
        handleClose();
      });
    } else {
      setStoryPoint(prevStoryPoint);
    }
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <div onClick={handleEditClick}
        className={`d-flex gap-1 align-items-center justify-content-center task-story-point-icon-div ${taskDetail?.userPermission?.can_i_edit === false
          ? "not-allowed-disable"
          : isUserProfile || isPublicPage
            ? "cursor_default"
            : "add_cursor"
          }`}
      >
        <span className="fs-12"><img alt="" loading="lazy" className="me-1" src={storyPoint >= 8 ? HgStrPtsIcon : storyPoint >= 4 ? MdStrPtsIcon : LowStrPtsIcon} />{storyPoint || 0}</span>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock={true}
        className="mt-10px"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuList className="custom-menu-box">
          {[...Array(11).keys()].slice(1).map((value) => (
            <MenuItem
              key={value}
              selected={value === storyPoint}
              onClick={() => handleUpdateTask(value)}
            >
              <img alt="" loading="lazy" src={value >= 8 ? HgStrPtsIcon : value >= 4 ? MdStrPtsIcon : LowStrPtsIcon} className="me-2" />
              {value}
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </>
  );
}

export default TaskStoryPoint;
