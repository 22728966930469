import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import FileViewBox from "../../../componets/comman/Projects/FileLightBox";
import ViewTaskLoader from "../../../componets/pageLoaders/projectLoaders/ViewTaskLoader";
import { api } from "../../../customAxios";
import { isPublicRoute } from "../../../Utils/helpers";
import AddSubBug from "../../Private/Projects/Tasks/AddSubBug";
import AddSubTask from "../../Private/Projects/Tasks/AddSubTask";
import TaskAttachments from "../../Private/Projects/Tasks/TaskAttachments";
import TaskComments from "../../Private/Projects/Tasks/TaskComments";
import TaskMediaRecorder from "../../Private/Projects/Tasks/TaskMediaRecorder";
import TaskProgress from "../../Private/Projects/Tasks/TaskProgress";
import ViewTaskSidebar from "../../Private/Projects/Tasks/ViewTaskSidebar";
function PublicViewTask(props) {
    const { setTaskId, setProgress } = props;
    const { projectUuid, taskId } = useParams();
    const [progress, setTaskProgress] = useState();
    const [taskDetail, setTaskDetail] = useState({});
    const [projectDetails, setProjectDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [projectUsers, setProjectUsers] = useState([]);
    const [attachmentFiles, setAttachmentFiles] = useState([]);
    const isPublicPage = isPublicRoute()
    const fetchTaskDetails = async () => {
        setIsLoading(true);

        try {
            const response = await api.get(`view-public-task/${taskId}`);
            setTaskDetail(response?.data?.task);
            setProjectDetails(response?.data?.projectStatuses);
            setProjectUsers(response?.data?.projectUsers);
            setAttachmentFiles(response?.data?.task?.files)
        } catch (error) {
            toast.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchTaskDetails()
    }, [taskId]);
    const handleGetTaskId = (id) => {
        setTaskId(id);
    };

    const [isOpenLightbox, setIsOpenLightbox] = useState(false);
    const [selectedImage, setSelectedImage] = useState([]);
    const handleCloseLightbox = () => {
        setIsOpenLightbox(false);
        setSelectedImage([]);
    };
    const handleImageClick = (file) => {
        const viewFile = {
            src: file,
            alt: file,
        };
        setSelectedImage([viewFile]);
        setIsOpenLightbox(true);
    };

    const handleHTMLContentClick = (event) => {
        event.preventDefault();
        const target = event.target;
        target.style.cursor = "pointer";
        if (target.tagName === "IMG") {
            handleImageClick(target.src);
        }
        if (target.tagName === "A") {
            target.setAttribute("target", "_blank");
            window.open(target.href, "_blank");
        }
    };

    return (
        <>
            <div className="sub-task py-5">
                {isLoading ? (
                    <ViewTaskLoader />
                ) : (
                    <div className="pt-2">
                        <div className="container pr-0">
                            <div
                                className="row responsive__row viewTaskDrwar"
                                style={{ width: "100%" }}
                            >
                                <div className="resposive__col-8 col-md-8">
                                    <div className="sub-task-content-top">
                                        <div className="heading view-task">
                                            <div
                                                className="d-flex mt-2 "
                                            >
                                                <div
                                                    className={`view_task_icon ${taskDetail?.task_type === "T" &&
                                                        taskDetail?.task_parent_id === null
                                                        ? "task-done-blue"
                                                        : taskDetail?.task_type === "B" &&
                                                            taskDetail?.task_parent_id === null
                                                            ? "bug"
                                                            : taskDetail?.task_type === "T" &&
                                                                taskDetail.task_parent_id !== null
                                                                ? "task-done-yellow"
                                                                : taskDetail?.task_type === "E"
                                                                    ? "userStory"
                                                                    : "bug"
                                                        }`}
                                                ></div>
                                                <h4
                                                    className={`capitalize-first w_96 `}
                                                >
                                                    {taskDetail?.task_name}
                                                </h4>
                                            </div>
                                            <div className="main-description">
                                                <div
                                                    className="text-div"
                                                >
                                                    <div className="d-flex align-items-center description_head ">
                                                        <h4 className="text-capitalize desc_heading">
                                                            Description
                                                        </h4>
                                                    </div>
                                                    <div
                                                        className={`desc-b html-content-custom-style ${!taskDetail.task_desc ? "pd-bottom" : ""
                                                            }`}
                                                    >
                                                        {showMore ||
                                                            taskDetail.task_desc?.length <= 1000 ? (
                                                            <span
                                                                className={`font-weight-400 description-preview ${!taskDetail.task_desc
                                                                    ? "no-description"
                                                                    : ""
                                                                    }`}
                                                                style={{
                                                                    whiteSpace: "initial",
                                                                    color: "#000000",
                                                                }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        taskDetail.task_desc &&
                                                                            taskDetail.task_desc[8]
                                                                            ? taskDetail.task_desc
                                                                            : "No description",
                                                                }}
                                                                onClick={handleHTMLContentClick}
                                                            />
                                                        ) : (
                                                            <span
                                                                className={`font-weight-400 description-preview ${!taskDetail.task_desc
                                                                    ? "no-description"
                                                                    : ""
                                                                    }`}
                                                                style={{ whiteSpace: "initial" }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        taskDetail.task_desc &&
                                                                            taskDetail.task_desc[8]
                                                                            ? taskDetail.task_desc.substr(
                                                                                0,
                                                                                1000
                                                                            ) + "..."
                                                                            : "No description",
                                                                }}
                                                                onClick={handleHTMLContentClick}
                                                            />
                                                        )}

                                                        {(taskDetail.task_desc
                                                            ? taskDetail.task_desc.length
                                                            : 0) > 1000 && (
                                                                <span
                                                                    tabIndex={0}
                                                                    onKeyPress={() => setShowMore(!showMore)}
                                                                    className="link-color cursor-hover text-decoration-none d-block f-14 font-weight-600"
                                                                    onClick={() => setShowMore(!showMore)}
                                                                >
                                                                    {" "}
                                                                    {showMore ? "Show less" : "Show more"}
                                                                </span>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="m-content">
                                        <TaskAttachments
                                            taskDetail={taskDetail}
                                            setTaskDetail={setTaskDetail}
                                            isSubTask={true}
                                            projectUser={projectUsers}
                                            attachmentFiles={attachmentFiles}
                                            setAttachmentFiles={setAttachmentFiles}
                                            setProgress={setProgress}
                                            isPublicPage={isPublicPage}

                                        />
                                        {(taskDetail?.task_type === "T" ||
                                            taskDetail?.task_type === "E" ||
                                            taskDetail?.task_type === "B") &&
                                            taskDetail && (
                                                <AddSubTask
                                                    taskDetail={taskDetail}
                                                    setTaskDetail={setTaskDetail}
                                                    taskId={taskId}
                                                    handleGetTaskId={handleGetTaskId}
                                                    projectDetails={projectDetails}
                                                    setProgress={setProgress}
                                                    projectUser={projectUsers}
                                                    isPublicPage={isPublicPage}

                                                />
                                            )}
                                        {(taskDetail?.task_type === "T" ||
                                            taskDetail?.task_type === "E") &&
                                            taskDetail.task_parent_id === null && (
                                                <AddSubBug
                                                    taskDetail={taskDetail}
                                                    setTaskDetail={setTaskDetail}
                                                    taskId={taskId}
                                                    handleGetTaskId={handleGetTaskId}
                                                    projectDetails={projectDetails}
                                                    projectUser={projectUsers}
                                                    setProgress={setProgress}
                                                    isPublicPage={isPublicPage}
                                                />
                                            )}
                                        <TaskMediaRecorder
                                            taskDetail={taskDetail}
                                            setProgress={setProgress}
                                            isPublicPage={isPublicPage}
                                        />
                                        {/* <br /> */}
                                        <div className="mt-4 mb-5">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <nav className="nav_prob">
                                                        <div
                                                            className="nav nav-tabs"
                                                            id="nav-tab"
                                                            role="tablist"
                                                        >
                                                            <button
                                                                className="nav-link active"
                                                                id="nav-home-tab"
                                                                data-bs-toggle="tab"
                                                                data-bs-target="#nav-home"
                                                                type="button"
                                                                role="tab"
                                                                aria-controls="nav-home"
                                                                aria-selected="true"
                                                            >
                                                                <b>Comments</b>
                                                            </button>
                                                            <button
                                                                className="nav-link mr-0"
                                                                id="nav-contact-tab"
                                                                data-bs-toggle="tab"
                                                                data-bs-target="#nav-contact"
                                                                type="button"
                                                                role="tab"
                                                                aria-controls="nav-contact"
                                                                aria-selected="false"
                                                            >
                                                                <b>Lifecycle Monitor</b>
                                                            </button>
                                                        </div>
                                                    </nav>
                                                    <div className="tab-content" id="nav-tabContent">
                                                        <div
                                                            className="tab-pane fade show active pt-2 editorviewtask commenteditor"
                                                            id="nav-home"
                                                            role="tabpanel"
                                                            aria-labelledby="nav-home-tab"
                                                        >
                                                            <TaskComments
                                                                taskDetail={taskDetail}
                                                                projectUser={projectUsers}
                                                                progress={progress}
                                                                setProgress={setProgress}
                                                                isPublicPage={isPublicPage}
                                                            />
                                                        </div>
                                                        <div
                                                            className="tab-pane fade mt-3"
                                                            id="nav-contact"
                                                            role="tabpanel"
                                                            aria-labelledby="nav-contact-tab"
                                                        >
                                                            <TaskProgress
                                                                progress={progress}
                                                                status_list={projectDetails}
                                                                isPublicPage={isPublicPage}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="resposive__col-4 col-md-4">
                                    <div className="heading view-task">
                                        <div
                                            className="d-flex mt-2 "
                                        >
                                            <div
                                                className={`view_task_icon ${taskDetail?.task_type === "T" &&
                                                    taskDetail?.task_parent_id === null
                                                    ? "task-done-blue"
                                                    : taskDetail?.task_type === "B" &&
                                                        taskDetail?.task_parent_id === null
                                                        ? "bug"
                                                        : taskDetail?.task_type === "T" &&
                                                            taskDetail.task_parent_id !== null
                                                            ? "task-done-yellow"
                                                            : taskDetail?.task_type === "E"
                                                                ? "userStory"
                                                                : "bug"
                                                    }`}
                                            ></div>
                                            <h4
                                                className={`capitalize-first w_96 `}
                                            >
                                                {taskDetail.task_name}
                                            </h4>
                                        </div>
                                    </div>
                                    <ViewTaskSidebar
                                        taskDetail={taskDetail}
                                        setTaskDetail={setTaskDetail}
                                        projectUser={projectUsers}
                                        status_list={projectDetails}
                                        setTaskProgress={setTaskProgress}
                                        isPublicPage={isPublicPage}
                                    />
                                </div>
                            </div>
                            <FileViewBox
                                images={selectedImage}
                                isOpen={isOpenLightbox}
                                onClose={handleCloseLightbox}
                            />
                        </div>
                    </div>
                )}
            </div>

        </>
    );
}
export default PublicViewTask;
