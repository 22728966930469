import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { signupClicked } from "../../Utils/analyticsHelper";

function Navbar() {
    // Initialize state for navbar visibility
    const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    // Toggle navbar visibility function
    const toggleNavbar = () => {
        setIsNavbarExpanded(!isNavbarExpanded);
    };
    const CloseNavbar = () => {
        setIsNavbarExpanded(false)
    }
    const handleSignupClick = () => {
        navigate("/signup");
        // signupClicked();
      };

    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary landing_navbar_main">
            <div className="container__land d-flex">
                <Link to="/" className="navbar-brand" onClick={() => navigate("/")}>
                    <img src={process.env.REACT_APP_BRAND_LOGO} alt="Logo" />
                </Link>
                <button
                    className={`toggle_navbar_button`}
                    onClick={toggleNavbar}
                >
                    <FontAwesomeIcon icon={isNavbarExpanded ? faTimes : faBars} />
                </button>
                <div
                    className={`navbar_collapse_landing_page ${isNavbarExpanded ? 'show' : ''}`}
                    id="navbarSupportedContent"
                >
                    <ul className="navbar-nav list_nav Landding_page_navbar">
                        <li className="nav-item">
                            <Link
                                to="/"
                                className={`nav-bar-link mx-3 ${location.pathname === "/" ? "active" : ""}`}
                                onClick={CloseNavbar}
                            >
                                Home
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/features"
                                className={`nav-bar-link mx-3 ${location.pathname === "/features" ? "active" : ""}`}
                                onClick={CloseNavbar}
                            >
                                Features
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/about-us"
                                className={`nav-bar-link mx-3 ${location.pathname === "/about-us" ? "active" : ""}`}
                                onClick={CloseNavbar}
                            >
                                About Us
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/contactus"
                                className={`nav-bar-link mx-3 ${location.pathname === "/contactus" ? "active" : ""}`}
                                onClick={CloseNavbar}
                            >
                                Contact Us
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/chrome-extension"
                                className={`nav-bar-link mx-3 ${location.pathname === "/chrome-extension" ? "active" : ""}`}
                                onClick={CloseNavbar}
                            >
                                Extension
                            </Link>
                        </li>
                    </ul>
                    <div className="search_button">
                        <button className="btn btn-primary sign-in" onClick={() => navigate("/signin")}>

                            Sign In
                        </button>
                        <button className="btn btn-primary btn__signup" onClick={handleSignupClick}>
                            Sign Up
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
