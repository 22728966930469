import React from "react";
import { useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import AuthLayouts from "../../layouts/AuthLayouts";
import MainLayout from "../../layouts/MainLayout";
import Feature from "./featurePage/Feature";
import Login from "./Authentication/Login";
import SignUp from "./Authentication/SignUp";
import ResetPassword from "./Authentication/ResetPassword";
import Forget from "./Authentication/Forget";
import AboutUs from "./About Us/AboutUs";
import Pricing from "./Pricing/Pricing";
import ContactUs from "./Contact/ContactUs";
import Home from "./landingPage/Home";
import VerifyEmail from "./Authentication/VerifyEmail";
import BacklogRefinement from "./Blogs/BacklogRefinement";
import WhatIsAgile from "./Blogs/WhatIsAgile";
import ScrumVSKanban from "./Blogs/ScrumVSKanban";
import SprintPlaning from "./Blogs/SprintPlaning";
import BugTracking from "./Blogs/BugTracking";
import PrivacyPolicy from "./landingPage/PrivacyPolicy";
import Extension from "./Extention/Extension";
import LatestUpdate from "./landingPage/LatestUpdate";
// import GoogleTagManager from "../../componets/GoogleTagManager";
import TagManager from 'react-gtm-module';
import TrialSignUp from "./Authentication/TrialSignUp";
import { useLocation } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

const PublicPortal = () => {
  const location = useLocation();

  useEffect(() => {
    const publicRoutes = ['/', '/signin', '/signup', '/trial', '/forget'];
    ReactPixel.init( process.env.REACT_APP_PIXEL_ID ); 

    if (publicRoutes.includes(location.pathname)) {
        TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID }); 
        ReactPixel.pageView(); 
    }
  }, [location]);

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      {/* <GoogleTagManager /> */}
      <Routes>
        <Route path="/" element={<AuthLayouts />}>
          <Route
            path="/"
            element={
              <React.Suspense fallback={<></>}>
                <Login />
              </React.Suspense>
            }
          />
          {/* <Route
            path="/features"
            element={
              <React.Suspense fallback={<></>}>
                <Feature />
              </React.Suspense>
            }
          /> */}
          <Route
            path="/privacy-policy"
            element={
              <React.Suspense fallback={<></>}>
                <PrivacyPolicy />
              </React.Suspense>
            }
          />
          {/* <Route
            path="/about-us"
            element={
              <React.Suspense fallback={<></>}>
                <AboutUs />
              </React.Suspense>
            }
          /> */}
          {/* <Route
            path="/pricing"
            element={
              <React.Suspense fallback={<></>}>
                <Pricing />
              </React.Suspense>
            }
          /> */}
          {/* <Route
            path="/contactus"
            element={
              <React.Suspense fallback={<></>}>
                <ContactUs />
              </React.Suspense>
            }
          /> */}
          <Route
            path="/backlog-refinement"
            element={
              <React.Suspense fallback={<></>}>
                <BacklogRefinement />
              </React.Suspense>
            }
          />
          <Route
            path="/what-is-agile"
            element={
              <React.Suspense fallback={<></>}>
                <WhatIsAgile />
              </React.Suspense>
            }
          />
          <Route
            path="/scrum-vs-kanban"
            element={
              <React.Suspense fallback={<></>}>
                <ScrumVSKanban />
              </React.Suspense>
            }
          />
          <Route
            path="/sprint-planing"
            element={
              <React.Suspense fallback={<></>}>
                <SprintPlaning />
              </React.Suspense>
            }
          />

          <Route
            path="/bug-tracking"
            element={
              <React.Suspense fallback={<></>}>
                <BugTracking />
              </React.Suspense>
            }
          />
          <Route
            path="/chrome-extension"
            element={
              <React.Suspense fallback={<></>}>
                <Extension />
              </React.Suspense>
            }
          /><Route
            path="/latest-update"
            element={
              <React.Suspense fallback={<></>}>
                <LatestUpdate />
              </React.Suspense>
            }
          />
        </Route>
        <Route path="/" element={<>{<AuthLayouts />}</>}>
          <Route
            path="/signin"
            element={
              <React.Suspense fallback={<></>}>
                <Login />
              </React.Suspense>
            }
          />
          <Route
            path="/signup"
            element={
              <React.Suspense fallback={<></>}>
                <SignUp />
              </React.Suspense>
            }
          />
          <Route
            path="/trial"
            element={
              <React.Suspense fallback={<></>}>
                <TrialSignUp />
              </React.Suspense>
            }
          />
          <Route
            path="/forget"
            element={
              <React.Suspense fallback={<></>}>
                <Forget />
              </React.Suspense>
            }
          />
          <Route
            path="/resetpassword"
            element={
              <React.Suspense fallback={<></>}>
                <ResetPassword />
              </React.Suspense>
            }
          />
          <Route
            path="/email-verify"
            element={
              <React.Suspense fallback={<></>}>
                <VerifyEmail />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </React.Suspense>
  );
};

export default PublicPortal;
