import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, Outlet, useLocation } from "react-router-dom";

export default function AuthLayouts() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const getTitle = () => {
    switch (location.pathname) {
      case "/":
        return "Signin";
      case "/signin":
        return "Signin";
      case "/forget":
        return "Forgot";
      case "/signup":
        return "Signup";
      case "/trial":
        return "Trial SignUp";
      default:
        return "Default Title";
    }
  };
  const handleHomePageNavigation = () => {
    window.open(process.env.REACT_APP_MAIN_SITE, '_blank', 'noopener,noreferrer');
  };
  return (

    <div className="login__wrapper">
      <Helmet>
        <title>{` ${getTitle()} | ${process.env.REACT_APP_PROJECT_NAME}`}</title>
      </Helmet>
      <nav className="navbar navbar-expand-lg navbar-light login_navbar_section">
        <div className="container navbarBox">
          <Link onClick={handleHomePageNavigation} className="navbar-brand">
            <img src={process.env.REACT_APP_BRAND__WHITE_LOGO} alt="" />
          </Link>

          <button className="btn btn-md btn-primary btn-back add_cursor" onClick={handleHomePageNavigation}>
            Back to Home
          </button>

        </div>
      </nav>
      <div className="outletPages">
        <Outlet />
      </div>
    </div>

  );
}
