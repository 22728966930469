import { Box, Grid, Popover } from "@mui/material";
// import { functiondisable, functionenable } from "../../helpers/helper";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import { api } from "../../customAxios";
import { getStatusColor, getStatusColorStyle, getStatusName, isPublicRoute } from "../../Utils/helpers";
import { editTask } from "../../Redux/Slice/Task";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function TaskStatus(props) {
  const {
    statusList,
    setTaskStatus,
    taskStatus,
    handleUpdateTask,
    taskDetail,
    setProgress,
    isViewTask,
    isSubtaskView,
    isList,
    listTaskStatus,
    refreshListStatusCount,
    projectId,
    taskListId,
    setTaskProgress,
    sprintId,
    refreshPage,
    isOverview,
    isUserProfile
  } = props;


  const dispatch = useDispatch();
  const { id } = useParams();
  const [listViewStatus, setListViewStatus] = useState();
  const [task, setTask] = useState();
  const isPublicPage = isPublicRoute()
  useEffect(() => {
    setListViewStatus(listTaskStatus);
    setTask(taskDetail);
  }, [taskDetail]);
  const taskProgress = () => {
    if (isViewTask && !isPublicPage) {
      api.get(`/tasks/${taskDetail?.task_id}/progress`).then((resp) => {
        setTaskProgress(resp.data);
      });
    }
  };
  useEffect(() => {
    if (taskDetail?.task_id && !isList) {
      setTimeout(() => {
        taskProgress(taskStatus);
      }, 500);
    }
  }, [taskStatus]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    if (isUserProfile || isPublicPage) {
      return;
    }
    else if (taskDetail?.userPermission?.can_i_edit === false) {
      toast.info("You don't have access to update task", { position: "bottom-right" });
      return;
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleSelectOption = (value) => {

    if (taskStatus === value.id) {
      return;
    }
    if (isList) {
      if (value.id !== listViewStatus) {
        setProgress(30);
        const taskId = taskDetail?.task_id;
        setListViewStatus(value.id);
        const payload = new FormData();
        payload.append(`status_id`, value.id);
        dispatch(editTask({ taskId, payload })).then((response) => {
          setProgress(60);
          toast.success("Task status updated successfully!");
          if (response?.payload?.task) {
            const data = {
              taskId: taskId,
              projectId: projectId ? projectId : id,
              listId: taskListId,
              sprint_id: sprintId,
              oldStatusId: task?.status_id,
              newStatusId: value.id,
            };
            if (!isSubtaskView && isOverview) {
              if (
                task.status_completed === 0 &&
                response?.payload?.task?.status_completed === 1
              ) {
                refreshListStatusCount(
                  data,
                  response?.payload?.task,
                  "addIntoComplete"
                );
              } else {
                refreshListStatusCount(data, response?.payload?.task);
              }
            } else if (isSubtaskView) {
              refreshPage(task?.status_id, response?.payload?.task);
            } else {
              if (refreshListStatusCount)
                refreshListStatusCount(data, response?.payload?.task);
            }
            setTimeout(() => {
              setTask({
                ...task,
                status_id: value.id,
              });
            }, 500);
          }
          setProgress(100);
        });
      }
    } else {
      setTaskStatus(value.id);
      handleUpdateTask("status", value.id);
    }
    handleClose();
  };
  const handleScroll = () => {
    handleClose();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <React.Fragment>
      <span
        onClick={handleClick}
        className={`${isSubtaskView ? "status-view-sub" : ""}${isViewTask ? "custom_select_view  status" : (isList || isOverview) && !isSubtaskView ? "task_items_list" : ""} ${isOverview && !isSubtaskView ? "w-100" : ""}  ${taskDetail?.userPermission?.can_i_edit === false ? 'not-allowed-disable' : isUserProfile || isPublicPage ? "cursor_default" : "add_cursor"}`}
        style={getStatusColorStyle(getStatusColor(isViewTask ? taskStatus : listViewStatus, statusList))}
      >
        {getStatusName(isViewTask ? taskStatus : listViewStatus, statusList)}
        {
          isViewTask && !isPublicPage &&
          <FontAwesomeIcon icon={faChevronDown} className="ms-2 f-10" />
        }
      </span>
      <Popover
        anchorEl={anchorEl}
        className={`${isViewTask ? "select-filter" : ""}`}
        id="account-menu"
        open={open}
        disableScrollLock={true}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            border: "1px solid #EAE9F2",
            padding: "10px",
            mt: 0.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Grid container item spacing={1} lg={12} md={12}>
          <Grid item lg={12} className="m-auto ">
            <Box>
              {statusList &&
                statusList.map((status) => (
                  <MenuItem
                    key={status}
                    className={`${status.id === taskStatus || status.id === listViewStatus
                      ? "add_bg"
                      : ""
                      }`}
                    onClick={() => handleSelectOption(status)}
                    style={{ color: status.color }}
                  >
                    {status.name}
                  </MenuItem>
                ))}
            </Box>
          </Grid>
        </Grid>
      </Popover>
    </React.Fragment>
  );
}
