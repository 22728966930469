import {
  AtomicBlockUtils,
  ContentState,
  EditorState,
  Modifier,
  convertFromRaw,
  convertToRaw
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { markdownToDraft } from "markdown-draft-js";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toast } from "react-toastify";
import { api } from "../../customAxios";
import { MARKDOWN_REGEX, URL_REGEX } from "../../Utils/constants";


function CustomTextEditor(props) {
  const {
    handleGetEditorState,
    description,
    taskId,
    isEditDescription,
    placeholder,
    isComment,
    isUploadTaskView,
    isUploadTempFile,
    isAddUpdate,
    projectUser,
    setProgress,
  } = props;
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (isEditDescription) {
      setEditorState(
        description
          ? EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(description).contentBlocks
            )
          )
          : EditorState?.createEmpty()
      );
    }
    if (isAddUpdate) {
      setEditorState(EditorState.createEmpty());
    }
  }, [isEditDescription, isAddUpdate, !isComment ? description : ""]);



  const onEditorStateChange = (currentEditorState) => {
    const contentState = currentEditorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();

    // Modify block types and convert to HTML
    const modifiedBlockMap = blockMap.map((block) => {
      if (block.getType() === "code-block") {
        return block.set("type", "code");
      }
      return block;
    });

    const modifiedContentState = ContentState.createFromBlockArray(
      modifiedBlockMap.toArray(),
      contentState.getEntityMap()
    );

    const rawContent = convertToRaw(modifiedContentState);

    // Convert modified content to HTML
    let htmlContent = draftToHtml(rawContent);

    // Replace <pre> tags with <pre><code> ... </code></pre>
    htmlContent = htmlContent.replace(/<pre>/g, '<pre><code>').replace(/<\/pre>/g, '</code></pre>');
    setEditorState(currentEditorState);
    handleGetEditorState && handleGetEditorState(htmlContent);
  };


  const handlePastedFiles = (files) => {
    const file = files[0];
    if (file.size > 2 * 1024 * 1024) {
      toast.error("File size exceeds the limit of 2 MB.");
      return;
    }
    if (setProgress) {
      setProgress(30);
    }
    if (isUploadTaskView) {
      const formData = new FormData();
      formData.append("model_id", taskId);
      formData.append("model_name", "Task");
      formData.append("file", files[0]);
      api
        .post(`/files`, formData)
        .then((action) => {
          if (action?.status === 200) {
            setEditorState(insertImage(action?.data?.name));
            props.handleGetFile(action?.data);
            if (setProgress) {
              setProgress(60);
            }
          }
        })
        .finally(() => {
          if (setProgress) {
            setProgress(100);
          }
        });
    } else if (isUploadTempFile) {
      const formData = new FormData();
      formData.append("file", files[0]);
      api
        .post("temp/files", formData)
        .then((response) => {
          setEditorState(insertImage(response?.data?.name));
          props.handleGetFile(response?.data);
          if (setProgress) {
            setProgress(60);
          }
        })
        .finally(() => {
          if (setProgress) {
            setProgress(100);
          }
        });
    }
  };

  const insertImage = (url) => {
    const contentState = editorState?.getCurrentContent();
    const widthPx = 200;
    const contentStateWithEntity = contentState?.createEntity(
      "IMAGE",
      "IMMUTABLE",
      {
        src: url,
        width: `${widthPx}px`,
        height: "auto",
        alignment: "left",
        border: "2px solid #ddd",
        borderRadius: "8px",
      }
    );
    const entityKey = contentStateWithEntity?.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
  };

  const mentionsSuggestion =
    projectUser?.length > 0 &&
    projectUser.map((user) => ({
      text: user.name,
      value: user?.name?.split(" ")[0],
    }));

  const mentionSuggestions =
    mentionsSuggestion &&
    mentionsSuggestion?.map((mention) => ({
      ...mention,
      component: (props) => (
        <div className="mention-suggestion">
          <img className="mention-suggestion-image" alt={mention.text} />
          <div className="mention-suggestion-text">{mention.text}</div>
        </div>
      ),
    }));
  const blockRenderer = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === "atomic") {
      return {
        component: AtomicBlock,
        editable: false,
      };
    }
    return null;
  };
  const AtomicBlock = (props) => {
    const { block, contentState } = props;
    const entity = contentState.getEntity(block.getEntityAt(0));
    const type = entity.getType();
    if (type === "IMAGE") {
      const { src } = entity.getData();
      return <img src={src} alt="AtomicBlock" style={{ maxWidth: "50%" }} />;
    }
    return null;
  };

  const handlePastedText = (text, html) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    // Check for URL match
    if (URL_REGEX.test(text)) {
      // Process URL paste
      const contentStateWithLink = contentState.createEntity(
        "LINK",
        "MUTABLE",
        { url: text }
      );
      const entityKey = contentStateWithLink.getLastCreatedEntityKey();

      const contentStateWithText = Modifier.replaceText(
        contentState,
        selectionState,
        text, // Display the pasted URL text
        null,
        entityKey
      );

      const newEditorStateWithLink = EditorState.push(
        editorState,
        contentStateWithText,
        "insert-characters"
      );

      setEditorState(EditorState.forceSelection(newEditorStateWithLink, contentStateWithText.getSelectionAfter()));
      return true; // Prevent default paste behavior for URL
    }

    // Check for Markdown match
    if (!html && text && MARKDOWN_REGEX.test(text)) {
      const rawContent = markdownToDraft(text);

      // Convert rawContent to ContentState
      const markdownContentState = convertFromRaw({
        entityMap: rawContent.entityMap,
        blocks: rawContent.blocks,
      });

      // Merge Markdown content with the existing content
      const newContentState = Modifier.replaceWithFragment(
        contentState,
        selectionState,
        markdownContentState.getBlockMap()
      );

      // Push the new content with markdown formatting to the editor state
      const newEditorStateWithMarkdown = EditorState.push(
        editorState,
        newContentState,
        "insert-fragment"
      );

      setEditorState(EditorState.forceSelection(newEditorStateWithMarkdown, newContentState.getSelectionAfter()));
      return true; // Prevent default paste behavior for Markdown
    }

    // Allow default paste behavior if no conditions matched
    return false;
  };


  return (
    <Editor
      wrapperClassName="wrapper-class"
      editorClassName="editor-class"
      toolbarClassName="toolbar-class"
      id="jobDescriptionFocus"
      placeholder={placeholder ? placeholder : "Enter text here"}
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      handlePastedFiles={handlePastedFiles}
      handlePastedText={handlePastedText}
      blockRendererFn={blockRenderer}
      className="p-10 text-black"
      toolbar={{
        options: [
          "inline",
          "blockType",
          "list",
          "textAlign",
          "link",
          "colorPicker",
          "emoji",
          "remove",
          "history"
        ],
        inline: {
          options: [
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "monospace",
          ],
          bold: { className: "custom-classname" },
          italic: { className: "custom-classname" },
          underline: { className: "custom-classname" },
          strikethrough: { className: "custom-classname" },
          monospace: { className: "custom-classname" },
        },
        list: {
          options: ["unordered", "ordered", "indent", "outdent"],
        },
        blockType: {
          options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote", 'Code'],
        },
        textAlign: {
          options: ["left", "center", "right", "justify"],
        },
        link: {
          inDropdown: false,
        },
      }}
      mention={{
        separator: " ",
        trigger: "@",
        suggestions: mentionSuggestions,
      }}
    />
  );
}

export default CustomTextEditor;
