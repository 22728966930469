import React, { useEffect, useState } from "react";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import gantt from "dhtmlx-gantt";
import { api } from "../../../../customAxios";
import { useParams } from "react-router-dom";

const ProjectTimeline = () => {
  const [viewMode, setViewMode] = useState("week");
  const [tasks, setTasks] = useState({ data: [], links: [] });
  const { id } = useParams();

  useEffect(() => {
    const fetchSprints = async () => {
      try {
        const response = await api.get(`/project/${id}/sprints`);
        const data = response.data.sprints;

        const preparedTasks = { data: [], links: [] };

        data.forEach((sprint) => {
          preparedTasks.data.push({
            id: sprint.id,
            text: sprint.name,
            start_date: sprint.start_date,
            end_date: sprint.end_date,
            parent: 0,
            progress: 0,
          });

          // Add tasks for each sprint
          sprint.tasks.forEach((task) => {
            preparedTasks.data.push({
              id: task.task_id,
              text: task.task_name,
              start_date: task.task_start_date,
              end_date: task.task_due_date,
              parent: sprint.id,
              progress: task.status_completed,
            });
          });
        });

        setTasks(preparedTasks);
      } catch (error) {
        console.error("Error fetching sprints:", error);
      }
    };

    fetchSprints();
  }, [id]);

  useEffect(() => {
    gantt.config.date_format = "%Y-%m-%d %H:%i";
    gantt.config.scale_height = 50;
  
    // Set Gantt chart to read-only mode
    gantt.config.readonly = true; 
  
    const currentYear = new Date().getFullYear();
    gantt.config.start_date = new Date(currentYear - 1, 0, 1); // 1 year back
    gantt.config.end_date = new Date(currentYear + 2, 11, 31); // 2 years ahead
  
    // Set initial scale to year
    gantt.config.scale_unit = "year";
    gantt.config.subscales = [
      { 
        unit: "month", 
        step: 1, 
        template: (date) => {
          return gantt.date.date_to_str("%F (%Y)")(date);
        } 
      },
      { 
        unit: "week", 
        step: 1, 
        template: (date) => gantt.date.date_to_str("%W")(date) 
      }
    ];
  
    // Enable horizontal scrolling
    gantt.config.scrollable = true;
    gantt.config.grid_width = 500;
  
    gantt.init("gantt_here");
  
    // Load data into Gantt
    gantt.parse(tasks);
  
    gantt.attachEvent("onLightbox", function (id) {
      return false;
    });
  
    return () => {
      gantt.clearAll(); // Clear all tasks when the component unmounts
    };
  }, [tasks]);
  
  const changeView = (mode) => {
    gantt.clearAll();
    setViewMode(mode);
  
    if (mode === "week") {
      // Week View Configuration
      gantt.config.scale_unit = "month";
      gantt.config.subscales = [
        {
          unit: "week",
          step: 1,
          template: (date) => "Week " + gantt.date.date_to_str("%W")(date),
        },
        {
          unit: "day",
          step: 1,
          template: (date) => gantt.date.date_to_str("%D %d")(date),
        }
      ];
    } else if (mode === "month") {
      gantt.config.scale_unit = "month";
      gantt.config.subscales = [
        {
          unit: "week",
          step: 1,
          template: (date) => "Week " + gantt.date.date_to_str("%W")(date),
        },
        {
          unit: "day",
          step: 1,
          template: (date) => gantt.date.date_to_str("%D %d")(date), 
        }
      ];
    }
  
    gantt.init("gantt_here");
    gantt.parse(tasks);
  };
  
  
  
  // useEffect(() => {
  //   // Configure Gantt settings
  //   gantt.config.date_format = "%Y-%m-%d %H:%i";
  //   gantt.config.scale_height = 50;

  //   // Initialize the Gantt chart
  //   gantt.init("gantt_here");

  //   // Load data into Gantt
  //   gantt.parse(tasks);

  //   // 1. Task Selected
  //   gantt.attachEvent("onTaskSelected", function (id) {
  //     console.log("Task selected:", id);
  //   });

  //   // 2. Task Add
  //   gantt.attachEvent("onTaskAdd", function (id, task) {
  //     console.log("Task added:", task);
  //     return true; // returning false will cancel task creation
  //   });

  //   // 3. Task Created
  //   gantt.attachEvent("onTaskCreated", function (task) {
  //     console.log("Task created:", task);
  //     return true;
  //   });

  //   // 4. After Task Add
  //   gantt.attachEvent("onAfterTaskAdd", function (id, task) {
  //     console.log("After task add:", task);
  //   });

  //   // 5. Task Update
  //   gantt.attachEvent("onTaskUpdate", function (id, task) {
  //     console.log("Task updated:", task);
  //     alert("Task updated!");
  //   });

  //   // 6. Task Delete
  //   gantt.attachEvent("onLightboxDelete", function (id) {
  //     console.log("Task deleted:", id);
  //     alert("Task deleted!");
  //   });

  //   // 7. Task Click
  //   gantt.attachEvent("onTaskClick", function (id, e) {
  //     console.log("Task clicked:", id);
  //     return true;
  //   });

  //   // 8. Task Double Click
  //   gantt.attachEvent("onTaskDblClick", function (id, e) {
  //     console.log("Task double-clicked:", id);
  //     return true;
  //   });

  //   // 9. Task Drag
  //   gantt.attachEvent("onTaskDrag", function (id, mode, task, original) {
  //     console.log("Task dragging:", id, mode, task);
  //     return true;
  //   });

  //   // 10. Task Loading
  //   gantt.attachEvent("onTaskLoading", function (task) {
  //     console.log("Task loading:", task);
  //     return true; // returning false will prevent task from loading
  //   });

  //   // 11. Before Task Display
  //   gantt.attachEvent("onBeforeTaskDisplay", function (id, task) {
  //     console.log("Before task display:", task);
  //     return true; // returning false will hide the task
  //   });

  //   // 12. Lightbox Opened
  //   gantt.attachEvent("onLightbox", function (id) {
  //     console.log("Lightbox opened for task:", id);
  //   });

  //   // 13. After Task Update
  //   gantt.attachEvent("onAfterTaskUpdate", function (id, task) {
  //     console.log("After task update:", task);
  //   });

  //   // 14. After Task Delete
  //   gantt.attachEvent("onAfterTaskDelete", function (id) {
  //     console.log("After task delete:", id);
  //   });

  //   // 15. Row Click
  //   gantt.attachEvent("onRowClick", function (id, target) {
  //     console.log("Row clicked:", id);
  //     return true;
  //   });

  //   // 16. Gantt Ready
  //   gantt.attachEvent("onGanttReady", function () {
  //     console.log("Gantt chart ready");
  //   });

  //   // 17. Before Task Changed
  //   gantt.attachEvent("onBeforeTaskChanged", function (id, mode, task) {
  //     console.log("Before task change:", id, mode, task);
  //     return true; 
  //   });

  //   // 18. Before Task Add
  //   gantt.attachEvent("onBeforeTaskAdd", function (id, task) {
  //     console.log("Before task add:", task);
  //     return true; 
  //   });

  //   gantt.attachEvent("onAfterLinkAdd", function (id, link) {
  //     console.log("Link added:", link);
  //     return true;
  //   })
  //   gantt.attachEvent("onLightbox", function (id) {
  //     console.log("OPen modanl window", id);
  //     return false;
  //   });
  //   return () => {
  //     gantt.clearAll();
  //   };
  // }, [tasks]);


  

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-end mb-2">
        <div className="buttons">
          <button
            className={`view_button ${
              viewMode === "week" ? "view_active" : ""
            } me-2`}
            onClick={() => changeView("week")}
          >
            Week View
          </button>
          <button
            className={`view_button ${
              viewMode === "month" ? "view_active" : ""
            } me-2`}
            onClick={() => changeView("month")}
          >
            Month View
          </button>
        </div>
      </div>

      <div
        id="gantt_here"
        style={{ width: "100%", height: "500px", backgroundColor: "#f8f9fa" }}
      ></div>
    </div>
  );
};

export default ProjectTimeline;
