// import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { signupCompleted } from "../../../Utils/analyticsHelper";
import constants from "../../../Utils/constants";
import { api } from "../../../customAxios";

function SignUp() {
  const [isChecked, setIsChecked] = useState(false);
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Please enter valid name")
      .matches(/^\w+/, "Please enter valid name")
      .max(128, "The name cannot be more than 128 characters"),
    email: Yup.string()
      .required("Please enter valid email address")
      .matches(constants.EMAIL_REGEX, "Please enter valid email address"),
    password: Yup.string()
      .required("Please enter your password")
      .min(6, "The password must be at least 6 characters"),
    password_confirmation: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),

    company_name: Yup.string()
      .required("Please enter your company name")
      .max(128, "Exceeded maximum character length of 128"),
  });

  const [errorMessage, setErrorMessage] = React.useState("");
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {},
  });

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onSubmit = async (data) => {
    if (!isChecked) {
      setErrorMessage("Please accept terms and conditions");
      return;
    }
    setIsLoading(true);
    await api
      .post("/auth/register", data)
      .then((response) => {
        setIsLoading(false);
        // signupCompleted();
        toast.success(response?.data?.message);
        navigate("/signin");
      })
      .catch((error) => {
        setErrorMessage(
          error.response?.data?.message === "The selected email is invalid."
            ? "User is not registered."
            : error.response?.data?.message
        );
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setErrorMessage("");
  };

  return (
    <div className="login__card">
      <h4 className="text-center mb-2">Sign Up Your Account</h4>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <label className="form-label form-label__email">Name</label>
          <input
            type="text"
            className={`form-control form-control__input f-12 w-100  ${errors.name ? "is-invalid_boder" : ""
              }`}
            placeholder="Enter name"
            {...register("name")}
          />
          <span className="danger-color error-msg">
            <span>{errors.name && errors.name.message}</span>
          </span>
        </div>
        <div className="">
          <label className="form-label form-label__email">Email</label>
          <input
            type="text"
            className={`form-control form-control__input f-12 w-100  ${errors.email ? "is-invalid_boder" : ""
              }`}
            placeholder="Enter email address"
            {...register("email")}
          />
          <span className="danger-color error-msg">
            <span>{errors.email && errors.email.message}</span>
          </span>
        </div>
        <div className="password_input">
          <label className="form-label form-label__email">Password</label>
          <input
            type={showPassword ? "text" : "password"}
            className={`form-control form-control__input f-12 w-100 ${errors.password ? "is-invalid_boder" : ""
              }`}
            placeholder="Enter password"
            {...register("password")}
          />
          <button
            type="button"
            className="password-toggle-button"
            onClick={() => setShowPassword(!showPassword)}
          >
            <FontAwesomeIcon
              icon={`fas ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
            />
          </button>
          <span className="danger-color error-msg">
            <span>{errors.password && errors.password.message}</span>
          </span>
        </div>
        <div className="password_input">
          <label className="form-label form-label__email">
            Confirm Password
          </label>
          <input
            type={showConfirmPassword ? "text" : "password"}
            className={`form-control form-control__input f-12 w-100 ${errors.password_confirmation ? "is-invalid_boder" : ""
              }`}
            placeholder={` ${errors.password_confirmation?.message
              ? errors.password_confirmation?.message
              : "Password confirmation"
              }`}
            {...register("password_confirmation", {
              required: "Password Confirmation is required",
            })}
          />
          <button
            type="button"
            className="password-toggle-button"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          >
            <FontAwesomeIcon
              icon={`fas ${showConfirmPassword ? "fa-eye" : "fa-eye-slash"}`}
            />
          </button>
          <span className="danger-color error-msg">
            <span>
              {errors.password_confirmation &&
                errors.password_confirmation.message}
            </span>
          </span>
        </div>
        <div className="">
          <label className="form-label form-label__name">Company Name</label>
          <input
            type="text"
            className={`form-control form-control__input f-12 w-100  ${errors.company_name ? "is-invalid_boder" : ""
              }`}
            placeholder="Enter comany name"
            {...register("company_name")}
          />
          <span className="danger-color error-msg">
            <span>{errors.company_name && errors.company_name.message}</span>
          </span>
        </div>
        <div className="mb-0 form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="exampleCheck1"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <label
            className="form-check-label custom-font-size-12"
            htmlFor="exampleCheck1"
          >
            I agree to your <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">terms</Link> and{" "}
            <Link to='/privacy-policy' target="_blank" rel="noopener noreferrer">privacy policy</Link>
          </label>
        </div>
        <span className="danger-color small-text-font">{errorMessage}</span>
        <button
          type="submit"
          className={`btn btn-primary all-btn-padding mt-3 w-100 ${isLoading ? "auth-cliper-pd" : ""
            }`}
          disabled={!isDirty || !isValid || isLoading || !isChecked}
        >
          {isLoading ? <ClipLoader color="#fff" size={20} /> : "Sign Up"}
        </button>
        <p
          className="text-center custom-font-size-14 mt-4"
        >
          Already have an account? <Link to="/signin">Sign In</Link>
        </p>
      </form>
    </div>
  );
}

export default SignUp;
