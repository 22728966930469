import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import dashboard from "../assets/images/Dashboard-sidebar-image.svg";
import project from "../assets/images/Project-sidebar-image.svg";
import task from "../assets/images/task-sidebar-image.svg";
import bug from "../assets/images/Bug-sidebar-image.svg";
import sprint from "../assets/images/Sprint-sidebar-image.svg";
import team from "../assets/images/Team-sidebar-image.svg";
import setting from "../assets/images/Setting-sidebar-icon.svg";
import LogoutImg from "../assets/images/Logout-sidebar-icon.svg";
import RecordingImg from "../assets/images/recording-sidebar-icon.svg";

function Sidebar(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { setIsSmallSidebar, isSmallSidebar, hideSidebar, setHideSidebar } =
    props;
  const handleClick = () => {
    setHideSidebar(true);
  };
  useEffect(() => {
    const handleResize = () => {
      const element = document.getElementsByClassName("content-wrapper")[0];
      if (isSmallSidebar) {
        element.style.transition = "width 0.3s ease";
        element.style.width = "100%";
        element.style.overflow = "auto";
      } else {
        element.style.transition = "width 0.3s ease";
        element.style.width = "85%";
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSmallSidebar]);

  useEffect(() => {
    const storedValue = localStorage.getItem("isSmallSidebar");
    setIsSmallSidebar(storedValue === "true");
  }, []);
  const toggleSidebar = () => {
    const updatedValue = !isSmallSidebar;
    setIsSmallSidebar(updatedValue);
    localStorage.setItem("isSmallSidebar", updatedValue.toString());
  };
  const logout = () => {
    localStorage.clear();
    navigate("/signin");
  };
  const moveToProfile = () => {
    navigate("/user/setting/manageaccount");
  };
  const moveToBilling = () => {
    navigate("/user/setting/billing");
  };
  const moveToPlans = () => {
    navigate("/user/setting/plans");
  };
  const moveToPreferences = () => {
    navigate("/user/setting/preferences");
  };

  return (
    <div
      className={`sidebar ${isSmallSidebar
        ? "sidebar_collapse"
        : "sidebar_expand sidebar__wrapper-res"
        } responsive__sidebar ${hideSidebar ? "w-60" : ""}`}
      onClick={handleClick}
    >
      <div className={`s-content`}>
        <div className="content">
          <ul className="top-items">
            <Link to="/user/dashboard">
              <li
                className={`sidebar_list_item ${location.pathname.includes("/user/dashboard") ? "active" : ""
                  } ${isSmallSidebar && "collapse_padding"}`}
              >
                <img className="icons" src={dashboard} alt="" />
                {!isSmallSidebar && <Link to="/user/dashboard">Dashboard</Link>}
              </li>
            </Link>
            <Link to="/user/projects">
              <li
                className={`sidebar_list_item ${location.pathname.includes("/user/project") ? "active" : ""
                  }  ${isSmallSidebar && "collapse_padding"}`}
              >
                <img className="icons" src={project} alt="" />
                {!isSmallSidebar && <Link to="/user/projects">Projects</Link>}
              </li>
            </Link>
            <Link to="/user/tasks">
              <li
                className={`sidebar_list_item ${location.pathname.includes("/user/tasks") ? "active" : ""
                  }  ${isSmallSidebar && "collapse_padding"}`}
              >
                <img className="icons" src={task} alt="" />
                {!isSmallSidebar && <Link to="/user/tasks">Tasks</Link>}
              </li>
            </Link>
            <Link to="/user/bugs">
              <li
                className={`sidebar_list_item ${location.pathname.includes("/user/bugs") ? "active" : ""
                  }  ${isSmallSidebar && "collapse_padding"}`}
              >
                <img className="icons" src={bug} alt="" />
                {!isSmallSidebar && <Link to="/user/bugs">Bugs</Link>}
              </li>
            </Link>
            <Link to="/user/sprints">
              <li
                className={`sidebar_list_item ${location.pathname.includes("/user/sprints") ? "active" : ""
                  }  ${isSmallSidebar && "collapse_padding"}`}
              >
                <img className="icons" src={sprint} alt="" />
                {!isSmallSidebar && <Link to="/user/sprints">Sprints</Link>}
              </li>
            </Link>
            <Link to="/user/recording">
              <li
                className={`sidebar_list_item ${location.pathname.includes("/user/recording") ? "active" : ""
                  }  ${isSmallSidebar && "collapse_padding"}`}
              >
                <img className="icons" src={RecordingImg} alt="" />
                {!isSmallSidebar && <Link to="/user/recording">Recordings</Link>}
              </li>
            </Link>
            {/* <Link to='/user/conversation'><li><FontAwesomeIcon icon={faMessage} className='icons' /><a>Conversation</a></li></Link> */}
            {/* <Link to='/user/Wikies'><li className={`${location.pathname === '/user/Wikies' ? 'active' : ''}`}><FontAwesomeIcon icon={faCalendarWeek} className='icons' /><a>Wikies</a></li></Link> */}
            <Link to="/user/teams">
              <li
                className={`sidebar_list_item ${location.pathname.includes("/user/teams") ? "active" : ""
                  }  ${isSmallSidebar && "collapse_padding"}`}
              >
                <img className="icons" src={team} alt="" />
                {!isSmallSidebar && <Link to="/user/teams">Teams</Link>}
              </li>
            </Link>
          </ul>

          <div className="bottom_navbar">
            <ul className="bottom-itmes">
              <div className="dropdown">
                <a
                  className="btn dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <li
                    className={`sidebar_list_item ${location.pathname.includes("/user/setting")
                      ? "active"
                      : ""
                      }  ${isSmallSidebar && "collapse_padding"}`}
                  >
                    <img
                      className="icons"
                      src={setting}
                      alt=""
                    />
                    {!isSmallSidebar && <Link>Account Settings</Link>}
                  </li>
                </a>
                <ul className="dropdown-menu drops setting_drop_ul" data-bs-auto-close="outside">
                  <li className="setting_drop_item" onClick={moveToProfile}>
                    Profile
                  </li>
                  <li className="setting_drop_item" onClick={moveToBilling}>
                    Billing
                  </li>
                  <li className="setting_drop_item" onClick={moveToPlans}>
                    Plans
                  </li>
                  <li className="setting_drop_item" onClick={moveToPreferences}>
                    Preferences
                  </li>
                </ul>
              </div>

              <li
                className={`sidebar_list_item ${isSmallSidebar && "collapse_padding"
                  }`}
                onClick={logout}
              >
                <img className="icons" src={LogoutImg} alt="" />

                {/* <FontAwesomeIcon icon={logout} className="icons" /> */}
                {!isSmallSidebar && <Link to="">Log out</Link>}
              </li>
            </ul>
          </div>
        </div>
        <span className="sidebar_toggle" onClick={toggleSidebar}>
          {isSmallSidebar ? (
            <FontAwesomeIcon icon={faChevronRight} />
          ) : (
            <FontAwesomeIcon icon={faChevronLeft} />
          )}
        </span>
      </div>
    </div>
  );
}

export default Sidebar;
